import { buildLinkUrl } from '@utils/functions/links';

export const getEditBookingHref = (appointmentIds: string[], isWebView: boolean = false) => {
  if (isWebView) {
    return buildLinkUrl('BOOKING_EDIT_APP', { appointmentIds: appointmentIds.join('/') });
  }

  return buildLinkUrl('BOOKING_EDIT', { appointmentIds });
};

export const getCancelBookingHref = (appointmentIds: string[], isWebView: boolean = false) => {
  if (isWebView) {
    return buildLinkUrl('BOOKING_CANCEL_APP', { appointmentIds: appointmentIds.join('/') });
  }

  return buildLinkUrl('BOOKING_CANCEL', { appointmentIds });
};
