import { Fragment, PropsWithChildren } from 'react';

export type RepeatContentProp = { count?: number };

export const RepeatContent = ({
  children,
  count,
}: PropsWithChildren<Required<RepeatContentProp>>) => (
  <>
    {Array(count)
      .fill('')
      .map((_, i) => (
        <Fragment key={i}>{children}</Fragment>
      ))}
  </>
);
