import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useToast } from '@therapie-ecommerce-ui/ui';
import { log } from '@core/logging/logging';
import { ManageBookingOperation } from '@features/booking/utils/booking.types';
import { checkAppointmentsContainsDuplicateTreatments } from '@features/booking/utils/booking.utils';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import { postMessageToMobileApp } from '@features/web-view/utils/post-message-to-mobile-app';
import { useFindImmutableAppointment } from '../useFindImmutableAppointment/useFindImmutableAppointment';
import { BookingAppointmentItem } from '../useFlattenBookingsWithAppointments/useFlattenBookingsWithAppointments.types';

export const useManageBooking = (appointments: BookingAppointmentItem[] = []) => {
  const router = useRouter();
  const toast = useToast();
  const { t } = useTranslation('booking');

  const { isWebView } = useIsWebView();

  const hasDuplicates = checkAppointmentsContainsDuplicateTreatments(appointments);

  const { immutableAppointment, isWithinCancellationPeriod } = useFindImmutableAppointment({
    appointments,
  });

  const { appointmentId, crmUserTreatmentItemId } = immutableAppointment ?? {};

  const isBookingFromCourse = !!crmUserTreatmentItemId;

  const getManageBookingRestriction = (operation: ManageBookingOperation) => {
    let restriction = '';

    if (isWithinCancellationPeriod) {
      restriction = t('bookings.manageBookingNotAllowed');
      if (isBookingFromCourse) {
        restriction = t('bookings.manageBookingNotAllowedWithForfeit');
      }
    } else if (operation === 'edit' && hasDuplicates) {
      restriction = t('bookings.manageBookingNotAllowedDuplicateTreatments');
    }

    return restriction;
  };

  const handleManageBooking = ({
    href,
    operation,
    isLinkOnWebview = false,
  }: {
    href: string;
    operation: ManageBookingOperation;
    isLinkOnWebview?: boolean;
  }) => {
    const restrictionMessage = getManageBookingRestriction(operation);

    if (restrictionMessage) {
      toast(
        {
          variant: 'info',
          message: restrictionMessage,
        },
        {
          toastId: appointmentId ?? '',
          draggable: true,
        }
      );

      log({
        level: 'info',
        category: operation === 'edit' ? 'booking-edit' : 'booking-cancel',
        message: 'manage_booking_restricted',
        messageContext: {
          isWithinCancellationPeriod,
          restrictionMessage,
          hasDuplicates,
        },
      });
      return;
    }

    if (isWebView && !isLinkOnWebview) {
      postMessageToMobileApp({ 'NAVIGATION/LINK': { href } });
      return;
    }

    return router.push(href);
  };

  return { handleManageBooking };
};
