import { Skeleton, Text } from '@therapie-ecommerce-ui/ui';
import { useGetClinicsByIdQuery } from '@/api/hooks/useGetClinicsQuery';

type ClinicNameProps = {
  clinicId: string;
  className?: string;
};

export const ClinicName = ({ clinicId, className }: ClinicNameProps) => {
  const { data: clinic, isLoading } = useGetClinicsByIdQuery(clinicId);

  return (
    <Text className={className} weight="semi-bold" color="night-100">
      {!isLoading ? clinic?.name : <Skeleton width={70} />}
    </Text>
  );
};
