import { ENV_REGION } from '@utils/constants/region.constants';
import { DASH_ENV } from '@utils/environment';
import { GetIsAppointmentDisabledProps } from './UpcomingBookings.types';

export const getIsAppointmentDisabled = ({
  selectedBooking,
  appointmentParentBooking,
}: GetIsAppointmentDisabledProps) =>
  !!selectedBooking && appointmentParentBooking.branchId !== selectedBooking?.branchId;

// UI-TODO: Remove until the multi-bookings feature is life and stable
const IS_MULTI_RESCHEDULE_APPOINTMENTS_ENABLED =
  process.env.NEXT_PUBLIC_MULTI_RESCHEDULE_APPOINTMENTS === 'true';
export const shouldRestrictOnlySingleAppointments = () => !IS_MULTI_RESCHEDULE_APPOINTMENTS_ENABLED;
