import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { isBefore } from 'date-fns';
import {
  Button,
  Checkbox,
  cx,
  Icon,
  Skeleton,
  sortObjectsListByAttributes,
  Text,
  useCheckbox,
  useRadio,
} from '@therapie-ecommerce-ui/ui';
import { Card } from '@components/card/Card';
import { LoadingContent } from '@components/loading-content/LoadingContent';
import { RepeatContent } from '@components/repeat-content/RepeatContent';
import { log } from '@core/logging/logging';
import { useGetSplitUserBookingsHistoryQuery } from '@features/booking/api/hooks/useGetUserBookingsHistoryQuery';
import { useManageBooking } from '@features/booking/hooks/useManageBooking/useManageBooking';
import {
  getCancelBookingHref,
  getEditBookingHref,
} from '@features/my-account/pages/my-bookings-page/MyBookingsPage.utils';
import { useIsWebView } from '@features/web-view/hooks/useIsWebView/useIsWebView';
import { BookingHistory, BookingHistoryItem } from '@/data/graphql/types';
import { AppointmentInfo } from '../appointment-info/AppointmentInfo';
import { AppointmentInfoSkeleton } from '../appointment-info/AppointmentInfo.skeleton';
import { SharedBookingBlockProps } from '../Bookings.types';
import {
  AppointmentSelectorProps,
  BookingActionsProps,
  SelectedBooking,
  UpcomingBookingInnerProps,
} from './UpcomingBookings.types';
import { getIsAppointmentDisabled } from './UpcomingBookings.utils';
import bookingStyles from '../bookings.module.scss';
import styles from './upcoming-bookings.module.scss';

let keyRef = '';

export const UpcomingBookings = (props: SharedBookingBlockProps) => {
  const {
    data: { upcomingBookings },
    isFetched,
  } = useGetSplitUserBookingsHistoryQuery();

  const sortedBookings = useMemo(
    () =>
      [...upcomingBookings].sort((a, b) =>
        isBefore(new Date(a.date!), new Date(b.date!)) ? -1 : 1
      ),
    [upcomingBookings]
  );

  // If this key changes, reset the internal selected bookings
  const upcomingBookingsKey = useMemo(() => {
    return sortedBookings
      .map((bookingHistory) =>
        bookingHistory.bookings
          ?.map((booking) => `${bookingHistory.branchId}/${booking.appointmentId}`)
          .join('')
      )
      .join('');
  }, [sortedBookings]);

  useEffect(() => {
    if (isFetched && keyRef !== upcomingBookingsKey) {
      log({
        category: 'query',
        message: 'user_upcoming_bookings',
        level: 'info',
        messageContext: {
          upcomingBookings: sortedBookings,
        },
      });
      keyRef = upcomingBookingsKey;
    }
  }, [isFetched, upcomingBookingsKey, sortedBookings]);

  return (
    <UpcomingBookingsInner upcomingBookings={sortedBookings} key={upcomingBookingsKey} {...props} />
  );
};

const UpcomingBookingsInner = ({
  shouldHideHeader,
  numBookingsShown,
  renderBody,
  renderFooter,
  onlySingleAppointment,
  upcomingBookings = [],
}: UpcomingBookingInnerProps) => {
  const { t } = useTranslation('booking');

  const [selectedBooking, setSelectedBooking] = useState<SelectedBooking>();

  const hasUpcomingAppointments = upcomingBookings.length > 0;

  const handleSelectAppointment = useCallback(
    (selectedAppointment: BookingHistoryItem, booking: BookingHistory) => {
      setSelectedBooking((prevSelectedBooking) => {
        if (onlySingleAppointment || !prevSelectedBooking) {
          return {
            branchId: booking.branchId!,
            appointments: [selectedAppointment],
          };
        }

        let appointments = prevSelectedBooking.appointments;

        const isPreviouslySelected = appointments.some(
          ({ appointmentId }) => appointmentId === selectedAppointment.appointmentId
        );

        appointments = isPreviouslySelected
          ? appointments.filter(
              ({ appointmentId }) => appointmentId !== selectedAppointment.appointmentId
            )
          : [...appointments, selectedAppointment];

        if (!appointments.length) {
          return undefined;
        }

        return {
          ...prevSelectedBooking,
          appointments: sortObjectsListByAttributes(appointments, ['time']),
        };
      });
    },
    [onlySingleAppointment]
  );

  let bookingShownCount = 0;

  const AppointmentComponent = onlySingleAppointment ? AppointmentRadio : AppointmentSelector;

  const bookings = (
    <LoadingContent.Consumer
      loadingContent={
        <AppointmentSelectorListSkeleton onlySingleAppointment={onlySingleAppointment} />
      }
      hasContent={hasUpcomingAppointments}
      emptyContent={
        <Text variant="h5" className={bookingStyles['bookings__empty']} weight="semi-bold">
          {t('bookings.noUpcoming')}
        </Text>
      }
    >
      {upcomingBookings.flatMap((booking) => {
        return booking.bookings?.map((appointment) => {
          if (numBookingsShown && bookingShownCount >= numBookingsShown) {
            return null;
          }

          bookingShownCount++;
          return (
            <AppointmentComponent
              key={`${appointment.serviceId}${appointment.time}`}
              booking={booking}
              appointment={appointment}
              onChange={() => handleSelectAppointment(appointment, booking)}
              isSelected={
                !!selectedBooking?.appointments?.find(
                  ({ appointmentId }) => appointmentId === appointment.appointmentId
                )
              }
              isDisabled={getIsAppointmentDisabled({
                selectedBooking,
                appointmentParentBooking: booking,
              })}
            />
          );
        });
      })}
    </LoadingContent.Consumer>
  );

  const footer = hasUpcomingAppointments && (
    <Card.Footer>
      <BookingActions appointments={selectedBooking?.appointments} />
    </Card.Footer>
  );

  return (
    <Card>
      {!shouldHideHeader && (
        <Card.Header>
          <Card.Title variant="h3">{t('bookings.upcoming')}</Card.Title>
        </Card.Header>
      )}
      <Card.Body gap="md">{renderBody ? renderBody(bookings) : bookings}</Card.Body>
      {renderFooter ? renderFooter(footer) : footer}
    </Card>
  );
};

// UI-TODO: Remove when multi appointment reschedule is released
const AppointmentRadio = ({
  booking,
  appointment,
  isSelected,
  onChange,
}: AppointmentSelectorProps) => {
  const { getRootProps, getInputProps, getControlProps } = useRadio({
    value: appointment?.appointmentId!,
    isChecked: isSelected,
    onChange: () => onChange(appointment),
  });

  return (
    <label {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        {...getControlProps()}
        className={cx(bookingStyles['bookings__appointment'], styles['appointment-selector'])}
      >
        <div className={styles['appointment-radio-wrapper']}>
          <Icon
            className={cx(styles['appointment-radio'], {
              [styles['appointment-radio--visible']]: isSelected,
              [styles['appointment-radio--hidden']]: !isSelected,
            })}
            family="therapie"
            name="check_circle_filled"
            color="trp-purple"
          />
          <Icon
            className={cx(styles['appointment-radio'], {
              [styles['appointment-radio--visible']]: !isSelected,
              [styles['appointment-radio--hidden']]: isSelected,
            })}
            family="therapie"
            name="radio_button_unchecked"
          />
        </div>
        <AppointmentInfo booking={booking} appointment={appointment} />
      </div>
    </label>
  );
};

const AppointmentSelector = ({
  booking,
  appointment,
  isSelected,
  onChange,
  isDisabled,
}: AppointmentSelectorProps) => {
  const { getCheckboxProps } = useCheckbox({
    isChecked: isSelected,
    onChange: () => onChange(appointment),
    isDisabled,
  });

  return (
    <label
      className={cx(styles['appointment-selector'], {
        [styles['appointment-selector--disabled']]: isDisabled,
      })}
    >
      <div className={bookingStyles['bookings__appointment']}>
        <Checkbox
          {...getCheckboxProps()}
          tag={'span'}
          className={styles['appointment-selector__icon']}
        />
        <AppointmentInfo booking={booking} appointment={appointment} />
      </div>
    </label>
  );
};

const AppointmentSelectorListSkeleton = ({
  onlySingleAppointment,
}: Pick<SharedBookingBlockProps, 'onlySingleAppointment'>) => (
  <RepeatContent count={3}>
    <div className={bookingStyles['bookings__appointment']}>
      <Skeleton circle={onlySingleAppointment} height={16} width={16} />
      <AppointmentInfoSkeleton />
    </div>
  </RepeatContent>
);

const BookingActions = ({ appointments = [] }: BookingActionsProps) => {
  const { t } = useTranslation('booking');
  const { isWebView } = useIsWebView();

  const { handleManageBooking } = useManageBooking(appointments);

  const isDisabled = appointments.length === 0;
  const i18nAppointmentsCount = appointments.length || 1;
  const appointmentIds = appointments.map(({ appointmentId }) => appointmentId!);

  return (
    <>
      <Button
        onClick={() =>
          handleManageBooking({
            operation: 'edit',
            href: getEditBookingHref(appointmentIds, isWebView),
          })
        }
        variant="filled"
        isFullWidth
        isDisabled={isDisabled}
      >
        {t('buttons.editBooking', { count: i18nAppointmentsCount })}
      </Button>
      <Button
        onClick={() =>
          handleManageBooking({
            operation: 'cancel',
            href: getCancelBookingHref(appointmentIds, isWebView),
          })
        }
        variant="outline-red"
        isFullWidth
        isDisabled={isDisabled}
      >
        {t('buttons.cancelBooking', { count: i18nAppointmentsCount })}
      </Button>
    </>
  );
};
