import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { getFreeConsultations } from '@features/booking/utils/booking.constants';
import { FreeConsultationTreatmentFields } from '@features/booking/utils/booking.types';
import { getIsMarketingFreeConsultation } from '@features/booking/utils/booking.utils';

export const useFreeConsultations = () => {
  const { t, i18n } = useTranslation('freeConsultation');
  const freeConsultations = useMemo(() => getFreeConsultations(t), [t]);

  const getTreatmentIsFreeConsultation = (treatment: FreeConsultationTreatmentFields) => {
    const defaultConsultation = freeConsultations.find(
      (consultation) => consultation.treatmentId === treatment.id
    );

    if (defaultConsultation) {
      return {
        slug: defaultConsultation?.slug,
        isFreeConsultation: true,
      };
    }

    if (getIsMarketingFreeConsultation(treatment, i18n.language)) {
      return {
        slug: treatment.slug,
        isFreeConsultation: true,
      };
    }

    return {
      slug: '',
      isFreeConsultation: false,
    };
  };

  return {
    freeConsultations,
    getTreatmentIsFreeConsultation,
  };
};

export type UseFreeConsultationsReturn = ReturnType<typeof useFreeConsultations>;
