import { useCallback, useMemo } from 'react';
import {
  findImmutableAppointment,
  findImmutableBookingHistoryItem,
  getBookingConditions,
} from '@features/booking/utils/booking.utils';
import { useFreeConsultations } from '../useFreeConsultations/useFreeConsultations';
import {
  GetIsImmutableAppointmentType,
  UseFindImmutableAppointment,
} from './useFindImmutableAppointment.types';

export const useFindImmutableAppointment = ({
  bookings,
  appointments,
}: UseFindImmutableAppointment) => {
  const { getTreatmentIsFreeConsultation } = useFreeConsultations();

  const getIsImmutableAppointment: GetIsImmutableAppointmentType = useCallback(
    (appointment) => {
      const { isFreeConsultation } = getTreatmentIsFreeConsultation({
        id: appointment.treatmentId!,
        category: appointment.treatmentCategory!,
      });
      return isFreeConsultation ? false : true;
    },
    [getTreatmentIsFreeConsultation]
  );

  const immutableAppointment = useMemo(() => {
    if (bookings) {
      return findImmutableAppointment(bookings, getIsImmutableAppointment);
    }
    if (appointments) {
      return findImmutableBookingHistoryItem(appointments, getIsImmutableAppointment);
    }
  }, [appointments, bookings, getIsImmutableAppointment]);

  const { isPastBooking, isWithinCancellationPeriod } = getBookingConditions(
    immutableAppointment?.time
  );

  return { immutableAppointment, isPastBooking, isWithinCancellationPeriod };
};
