import { Title, Skeleton } from '@therapie-ecommerce-ui/ui';
import { RepeatContent, RepeatContentProp } from '@components/repeat-content/RepeatContent';

export const AppointmentInfoSkeleton = ({ count = 1 }: RepeatContentProp) => (
  <RepeatContent count={count}>
    <div>
      <Title variant="h5">
        <Skeleton style={{ maxWidth: 200 }} />
      </Title>
      <Skeleton height={30} />
    </div>
  </RepeatContent>
);
