import React from 'react';

import { IconButton, cx, Title } from '@therapie-ecommerce-ui/ui';

import {
  CardProps,
  CardHeaderProps,
  CardTitleProps,
  CardBodyProps,
  CardFooterProps,
  CardIconButtonProps,
  CardRowProps,
} from './Card.types';

import styles from './card.module.scss';

export const Card = ({ className, children, ...rest }: CardProps) => {
  const classNames = cx(styles['card'], className);

  return (
    <article className={classNames} {...rest}>
      {children}
    </article>
  );
};

const CardHeader = ({ className, children, isHidden = false, ...rest }: CardHeaderProps) => {
  if (isHidden) return null;

  return (
    <header className={cx(styles['card__header'], className)} {...rest}>
      {children}
    </header>
  );
};

const CardTitle = ({ className, children, ...rest }: CardTitleProps) => {
  const classNames = cx(styles['card__title'], className);
  return (
    <Title variant="h3" weight="bold" className={classNames} {...rest}>
      {children}
    </Title>
  );
};

const CardBody = ({ className, children, hasPadding = true, gap, ...rest }: CardBodyProps) => {
  return (
    <div
      className={cx(
        styles['card__body'],
        { [styles[`card__body--gap-${gap}`]]: !!gap },
        { [styles['card__body--padding']]: hasPadding },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

const CardFooter = ({ className, children, gap = 'md', isTighter, ...rest }: CardFooterProps) => {
  return (
    <footer
      className={cx(
        styles['card__footer'],
        {
          [styles[`card__footer--gap-${gap}`]]: !!gap,
          [styles[`card__footer--tighter`]]: isTighter,
        },
        className
      )}
      {...rest}
    >
      {children}
    </footer>
  );
};

const CardRow = ({ className, children, hasBorder = false, ...rest }: CardRowProps) => {
  return (
    <div
      className={cx(styles['card__row'], { [styles['card__row--bordered']]: hasBorder }, className)}
      {...rest}
    >
      {children}
    </div>
  );
};

const CardIconButton = React.forwardRef<HTMLButtonElement, CardIconButtonProps>(
  ({ name, label, className, position = 'top-right', variant = 'outline', ...rest }, ref) => {
    const classNames = cx(
      styles['card__icon-button'],
      { [styles[`card__icon-button--${position}`]]: position },
      className
    );
    return (
      <IconButton
        aria-label={label ?? name}
        className={classNames}
        variant={variant}
        iconProps={{
          name,
          family: 'therapie',
          size: 'xs',
        }}
        ref={ref}
        {...rest}
      />
    );
  }
);

CardIconButton.displayName = 'CardIconButton';

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Title = CardTitle;
Card.Footer = CardFooter;
Card.Row = CardRow;
Card.IconButton = CardIconButton;
