import { Text, Title } from '@therapie-ecommerce-ui/ui';

import { getFormattedDate } from '@utils/functions/dates';
import { formatAppointmentSchedule } from '@features/booking/utils/booking.utils';

import { ClinicName } from '../../clinic-name/ClinicName';
import { AppointmentInfoProps } from './AppointmentInfo.types';

import styles from './appointment-info.module.scss';

export const AppointmentInfo = ({ booking, appointment }: AppointmentInfoProps) => (
  <div className={styles['info']}>
    <Title variant="h5" aria-label="Booking Date">
      {getFormattedDate({ date: appointment.time!, format: 'READABLE_DAY_MONTH' })}
    </Title>
    <div className={styles['info__time-and-date']}>
      <Text weight="semi-bold" color="night-100">
        {formatAppointmentSchedule(appointment)}
      </Text>
      <ClinicName clinicId={booking.branchId!} className={styles['info__description']} />
    </div>
    <Text className={styles['info__description']} weight="semi-bold" color="night-100">
      {appointment?.treatmentTitle}
    </Text>
  </div>
);
